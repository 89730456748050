// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reservation-faq-js": () => import("./../../../src/pages/reservation/faq.js" /* webpackChunkName: "component---src-pages-reservation-faq-js" */),
  "component---src-pages-reservation-info-js": () => import("./../../../src/pages/reservation/info.js" /* webpackChunkName: "component---src-pages-reservation-info-js" */),
  "component---src-pages-reservation-note-js": () => import("./../../../src/pages/reservation/note.js" /* webpackChunkName: "component---src-pages-reservation-note-js" */),
  "component---src-pages-reservation-reserve-js": () => import("./../../../src/pages/reservation/reserve.js" /* webpackChunkName: "component---src-pages-reservation-reserve-js" */),
  "component---src-pages-rooms-flower-js": () => import("./../../../src/pages/rooms/flower.js" /* webpackChunkName: "component---src-pages-rooms-flower-js" */),
  "component---src-pages-rooms-mountain-js": () => import("./../../../src/pages/rooms/mountain.js" /* webpackChunkName: "component---src-pages-rooms-mountain-js" */),
  "component---src-pages-rooms-star-js": () => import("./../../../src/pages/rooms/star.js" /* webpackChunkName: "component---src-pages-rooms-star-js" */),
  "component---src-pages-spaces-barbecue-js": () => import("./../../../src/pages/spaces/barbecue.js" /* webpackChunkName: "component---src-pages-spaces-barbecue-js" */),
  "component---src-pages-spaces-bench-js": () => import("./../../../src/pages/spaces/bench.js" /* webpackChunkName: "component---src-pages-spaces-bench-js" */),
  "component---src-pages-spaces-common-js": () => import("./../../../src/pages/spaces/common.js" /* webpackChunkName: "component---src-pages-spaces-common-js" */),
  "component---src-pages-spaces-landscape-js": () => import("./../../../src/pages/spaces/landscape.js" /* webpackChunkName: "component---src-pages-spaces-landscape-js" */)
}

